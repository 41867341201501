import _react from "react";
import _factory from "./factory";
var exports = {};
var React = _react;
var factory = _factory;

if (typeof React === "undefined") {
  throw Error("create-react-class could not find the React object. If you are using script tags, " + "make sure that React is being loaded before create-react-class.");
} // Hack to grab NoopUpdateQueue from isomorphic React


var ReactNoopUpdateQueue = new React.Component().updater;
exports = factory(React.Component, React.isValidElement, ReactNoopUpdateQueue);
export default exports;